import Layout from '@/views/layout'

export default [{
  path: '/order',
  component: Layout,
  redirect: '/order/index',
  meta: {
    title: '业务管理'
  },
  children: [
    {
      path: 'register',
      component: () => import('@/views/agent/register/index'),
      name: 'AgentRegister',
      meta: { title: '顾客注册', agentOnly: true }
    },{
      path: 'authentication',
      component: () => import('@/views/agent/authentication/index'),
      name: 'Authentication',
      meta: { title: '顾客认证'}
    },
    {
      path: 'uplevel',
      component: () => import('@/views/order/up-level/index'),
      name: 'OrderUpLevel',
      meta: { title: '订货' }
    },
    {
      path: 'reconsume',
      component: () => import('@/views/order/re-consume/index'),
      name: 'OrderReConsume',
      meta: { title: '折扣订货' }
    },
    {
      path: 'service',
      component: () => import('@/views/order/service/index'),
      name: 'OrderService',
      meta: { title: '周转货订货' }
    },
    {
      path: 'exchange',
      component: () => import('@/views/order/exchange/index'),
      name: 'OrderExchange',
      meta: { title: '积分换货' }
    },
    {
      path: 'repurchase',
      component: () => import('@/views/order/re-purchase/index'),
      name: 'OrderRePurchase',
      meta: { title: '新零售订货' }
    },
    {
      path: 'index',
      component: () => import('@/views/order/index'),
      name: 'OrderList',
      meta: { title: '订单查询' }
    },
    {
      path: 'out',
      component: () => import('@/views/agent/order-out/index'),
      name: 'AgentOrderOut',
      meta: { title: '提货管理' }
    },
    {
      path: 'out/ds',
      component: () => import('@/views/agent/order-out/ds/ds-order'),
      name: 'DsOrderOut',
      meta: { title: '电商提货管理' }
    },
    {
      path: 'out/detail',
      component: () => import('@/views/agent/order-out/detail/index'),
      name: 'OutOrderDetail',
      meta: { title: '出库单' },
      hidden: true
    },
    {
      path: 'ship-view',
      component: () => import('@/views/order/ship/view/index'),
      name: 'OrderShipView',
      meta: { title: '物流查询' },
      hidden: true
    },
    {
      path: 'detail',
      component: () => import('@/views/order/detail/index'),
      name: 'OrderDetail',
      meta: { title: '查看订单' },
      hidden: true
    },
    {
      path: 'orderoutmanager',
      component: () => import('@/views/agent/order-out-manager/index'),
      name: 'OrderOutManager',
      meta: { title: '出库单管理' },
    },
    {
      path: 'outordermdetail',
      component: () => import('@/views/agent/order-out-manager/detail/index'),
      name: 'poOutsInfo',
      meta: { title: '查看详情' },
      hidden: true
    },
    {
      path: 'pooutsend',
      component: () => import('@/views/agent/poout-send/index'),
      name: 'PoOutSend',
      meta: { title: '出库单发货' },
      hidden: true
    },
    {
      path: 'pooutsendsinfo',
      component: () => import('@/views/agent/poout-send/detail/index'),
      name: 'PoOutSendsInfo',
      meta: { title: '查看详情' },
      hidden: true
    },
    {
      path: 'ship',
      component: () => import('@/views/order/ship/index'),
      name: 'OrderShipList',
      meta: { title: '物流查询' }
    },
    {
      path: 'agentpooutsend',
      component: () => import('@/views/agent/agent-poout-send/index'),
      name: 'AgentPoOutSend',
      meta: { title: '收货确认' },
    },
    {
      path: 'cruise-information',
      component: () => import('@/views/order/cruise-information/CruiseInformation.vue'),
      name: 'CruiseInformation',
      meta: { title: '游轮游信息管理' }
    },
    {
      path: 'cruise-information-save',
      component: () => import('@/views/order/cruise-information/CruiseInformationSave.vue'),
      name: 'CruiseInformationSave',
      meta: { title: '游轮游信息保存' },
      hidden: true
    }
  ]
}]
